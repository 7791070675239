@import 'variables';
@import '../../node_modules/bootstrap/scss/bootstrap';

body {
  background-color: white;
}
.sigCanvas {
  border: 1px solid black;
  width: 100%;
  min-height: 300px;
  background: white;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}