@font-face {
  font-family: 'Baloo2';
  src: url('./../public/Baloo2.ttf') format(truetype);
}

* {
  font-family: 'Baloo2';
}

body {
  overflow-x: hidden !important;
}

@media (min-width: 681px) {
  body .aa-Panel {
    position: fixed !important;
    z-index: 150;
    top: 50px !important;
  }
}
